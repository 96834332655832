<template>
  <div class="js-common-wrap">
    <div class="js-common-head">
      <label>用户管理</label>
      <div>
        <el-button v-if='getTempraryShow' type="primary" size="small" @click.stop="handleOrganization">组织管理</el-button>
        <el-button v-if='getTempraryShow' type="primary" size="small" @click.stop="handleEdit(false,'')">创建用户</el-button>
      </div>
    </div>
    <div class="js-common-content">
      <el-table class="js-table" :data="tableData" height="100%" empty-text="暂无数据">
        <el-table-column prop="name" label="用户名"></el-table-column>
        <el-table-column prop="realName" label="姓名"></el-table-column>
        <el-table-column prop="description" label="个人介绍"></el-table-column>
        <el-table-column prop="roleName" label="角色"></el-table-column>
        <el-table-column prop="deptName" label="组织"></el-table-column>
        <el-table-column prop="gmtCreated" label="创建时间" width="160"></el-table-column>
        <el-table-column prop="state" label="状态">
          <template slot-scope="scope">
            <span v-if="scope.row.status">停用</span>
            <span v-else>启用</span>
          </template>
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <i class="el-icon-edit-outline" @click.stop="handleEdit(true,scope.row)"></i>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <common-pagination v-model="pageOptions" @curPageChange="handleCurPageChange"></common-pagination>
  </div>
</template>
<script>
import CommonPagination from '@/components/CommonPagination'
import { mapGetters } from "vuex";

export default {
  name: '',
  components: {
    CommonPagination,
  },
  data() {
    return {
      tableData: [],
      editDialogVisible: false,
      pageOptions:{
        page: 1,
        perPage: 20,
        total: 0
      },
      type: false
    }
  },
  computed: {
    ...mapGetters([ "getTempraryShow"]),
  },
  mounted() {
    this.getList()
  },
  updated(){
    console.log(this.getTempraryShow)
  },
  methods: {
    getList(){
      this.$axios({
        method: 'get',
        url: 'api/csp/mgr/v1/user/',
        params: {
          page: this.pageOptions.page,
          perPage: this.pageOptions.perPage
        }
      }).then((response)=>{
        let data = response.data
        if(!data.status){
          this.tableData = data.data.rows
          this.pageOptions.total = data.data.total
        }else{
          this.$message({
            type: 'error',
            message: data.msg,
            duration: 1000
          })
        }
      })
    },
    // 分页
    handleCurPageChange(){
      this.getList()
    },
    handleEdit(flag,row){
      let options = ''
      if(flag){
        options = row
        this.type = 1
      }else{
        options = {
          avatar: '',
          tenantCode: '',
          name: '',
          realName: '',
          password: '',
          password: '',
          mobile: '',
          description: '',
          status: '',
        }
        this.type = 0
      }
      this.$router.push({
        path: `/system/user/createUser`,
        query:{
          options: JSON.stringify(options),
          type: this.type
        }
      })
    },
    handleOrganization(){
      this.$router.push({
        path: `/system/user/organization`
      })
    }
  },
}
</script>

<style lang="scss" scoped>

</style>
