var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "js-common-wrap" },
    [
      _c("div", { staticClass: "js-common-head" }, [
        _c("label", [_vm._v("用户管理")]),
        _c(
          "div",
          [
            _vm.getTempraryShow
              ? _c(
                  "el-button",
                  {
                    attrs: { type: "primary", size: "small" },
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        return _vm.handleOrganization.apply(null, arguments)
                      },
                    },
                  },
                  [_vm._v("组织管理")]
                )
              : _vm._e(),
            _vm.getTempraryShow
              ? _c(
                  "el-button",
                  {
                    attrs: { type: "primary", size: "small" },
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        return _vm.handleEdit(false, "")
                      },
                    },
                  },
                  [_vm._v("创建用户")]
                )
              : _vm._e(),
          ],
          1
        ),
      ]),
      _c(
        "div",
        { staticClass: "js-common-content" },
        [
          _c(
            "el-table",
            {
              staticClass: "js-table",
              attrs: {
                data: _vm.tableData,
                height: "100%",
                "empty-text": "暂无数据",
              },
            },
            [
              _c("el-table-column", {
                attrs: { prop: "name", label: "用户名" },
              }),
              _c("el-table-column", {
                attrs: { prop: "realName", label: "姓名" },
              }),
              _c("el-table-column", {
                attrs: { prop: "description", label: "个人介绍" },
              }),
              _c("el-table-column", {
                attrs: { prop: "roleName", label: "角色" },
              }),
              _c("el-table-column", {
                attrs: { prop: "deptName", label: "组织" },
              }),
              _c("el-table-column", {
                attrs: { prop: "gmtCreated", label: "创建时间", width: "160" },
              }),
              _c("el-table-column", {
                attrs: { prop: "state", label: "状态" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.status
                          ? _c("span", [_vm._v("停用")])
                          : _c("span", [_vm._v("启用")]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "操作" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("i", {
                          staticClass: "el-icon-edit-outline",
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              return _vm.handleEdit(true, scope.row)
                            },
                          },
                        }),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c("common-pagination", {
        on: { curPageChange: _vm.handleCurPageChange },
        model: {
          value: _vm.pageOptions,
          callback: function ($$v) {
            _vm.pageOptions = $$v
          },
          expression: "pageOptions",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }